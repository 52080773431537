import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
const { default: SidebarMerchant } = require("../common/sidebar")
const { default: TopbarMerchant } = require("../common/topbar")




const ReportMerchant = (props) => {
    const [getFilter, setFilter] = useState({})
    const [getHeaderForm, setHeaderForm] = useState(JSON.stringify({}))

    const cookie = new Cookies()




    const exportcsv = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setHeaderForm(JSON.stringify(ffilter))

            document.getElementById("formpost").submit()
        } catch (error) {
            console.log(error)
        }
    }

    const setData = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setHeaderForm(JSON.stringify(ffilter))

        } catch (error) {
            console.log(error)
        }
    }




    useEffect(() => {
    }, [])

    useEffect(() => {
        setData()
    })



    return (
        <>
            <TopbarMerchant handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">

                    <div className="flex flex-gap-extended options_table_container">
                        <table className="full-width options_table">

                            <tr>
                                <td>
                                    <label>Status</label>
                                </td>
                                <td>
                                    <select name="status" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }); setData() }}>
                                        <option value="">All</option>
                                        <option value="SUCCESS">Success</option>
                                        <option value="FAILED">Failure</option>
                                        <option value="PENDING">Pending</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>From:</label>
                                </td>
                                <td>
                                    <input name="updated_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $gte: newDate.getTime() } }); setData() }} type="Date" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>To:</label></td>
                                <td>
                                    <input name="updated_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $lte: newDate.getTime() + 86399999 } }); setData() }} type="Date" />
                                </td>

                            </tr>

                        </table>
                    </div>
                    <form id="formpost" action={`${process.env.REACT_APP_URL}/merchant/reporting`} target="_blank" method="POST">
                        <input name="token" value={cookie.get('merchant')} ></input>
                        <input name="filter" value={getHeaderForm} ></input>
                        <input type="submit"></input>
                    </form>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { exportcsv() }}> Download</button>
                    </div>
                </div>
                {/* <div className="tran_table">
                    <div>

                        <table>
                            <tr>
                                <th>File Name</th>
                                <th>Created_at</th>
                                <th>Download</th>
                                <th>Expires</th>
                            </tr>
                            <tr>
                                <td>hello</td>
                                <td>hello</td>
                                <td>
                                    <div className="buttonset1">
                                        <button>Download</button>
                                    </div>
                                </td>
                                <td>hello</td>
                            </tr>
                        </table>
                    </div>
                </div> */}
            </div>
        </>
    )
}

export default ReportMerchant