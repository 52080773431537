import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import "../../Common/Common.css";
const { default: SidebarAdmin } = require("../common/sidebar")
const { default: TopbarAdmin } = require("../common/topbar")







const Acquirer = (props) => {
    const [getRoute, setRoute] = useState([{}])
    const [getAcquirer, setAcquirer] = useState({})
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const cookie = new Cookies()

    const create_acquirer = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    ...getAcquirer
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_acquirer()
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_acquirer = async () => {
        try {
            setAcquirerlist([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const change_state_acquiere = async (acquirer, status) => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/change_state_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    acquirer: acquirer,
                    status: status
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    props.setLoading("success")
                    console.log("changed")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_route = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_route`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('user')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setRoute(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_acquirer();
        get_route();
    }, [""])




    return (
        <>
            <TopbarAdmin />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">
                    <div className="flex flex-gap-extended options_table_container">
                        <table className="options_table full-width">
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input name="name" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter Name" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        getAcquirer.route == "nexacosmos" ? <label>Client ID:</label> : getAcquirer.route == "pioneer" || getAcquirer.route == "nexapay" || getAcquirer.route == "nexanew" ? <label>PAY ID:</label> : <label>VPA:</label>
                                    }
                                </td>
                                <td>
                                    <input name="vpa" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter VPA" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Route:</label>
                                </td>
                                <td>
                                    <select name="route" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }}>
                                        <option>Select Acquirer</option>
                                        {
                                            getRoute.map((e, index) => {
                                                return (
                                                    <option value={e.name}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            {
                                getAcquirer.route == "nexacosmos" ?
                                    <tr>
                                        <td>
                                            <label>Response Hash Key::</label>
                                        </td>
                                        <td>
                                            <input name="payid" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter PAY ID" />
                                        </td>
                                    </tr>
                                    : <></>
                            }
                            {
                                getAcquirer.route == "fino" ?
                                    <tr>
                                        <td>
                                            <label>PAYID:</label>
                                        </td>
                                        <td>
                                            <input name="payid" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter PAY ID" />
                                        </td>
                                    </tr> : <></>
                            }
                            {
                                getAcquirer.route == "nexacosmos" ?
                                    <tr>
                                        <td>
                                            <label>Request Salt Key:</label>
                                        </td>
                                        <td>
                                            <input name="salt" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter SALT" />
                                        </td>
                                    </tr>
                                    : <></>
                            }
                            {
                                getAcquirer.route == "fino" ?
                                    <tr>
                                        <td>
                                            <label>SALT:</label>
                                        </td>
                                        <td>
                                            <input name="salt" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter SALT" />
                                        </td>
                                    </tr> : <></>
                            }
                            {
                                getAcquirer.route == "nexacosmos" ?
                                    <tr>
                                        <td>
                                            <label>Response Salt Key:</label>
                                        </td>
                                        <td>
                                            <input name="dec_salt" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter SALT" />
                                        </td>
                                    </tr> : <></>
                            }
                        </table>
                        <table className="options_table full-width">
                            <tr>
                                <td>
                                    {
                                        getAcquirer.route == "nexacosmos" ? <label>Request Hash Key:</label> : getAcquirer.route == "pioneer" || getAcquirer.route == "nexapay" || getAcquirer.route == "nexanew" ? <label>ENC KEY:</label> : <label>MCC:</label>
                                    }
                                </td>
                                <td>
                                    <input name="mcc" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter MCC" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {
                                        getAcquirer.route == "nexacosmos" ? <label>Secret Key:</label> : getAcquirer.route == "pioneer" || getAcquirer.route == "nexapay" || getAcquirer.route == "nexanew" ? <label>SALT:</label> : <label>PN:</label>
                                    }
                                </td>
                                <td>
                                    <input name="pn" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter PN" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Type:</label>
                                </td>
                                <td>
                                    <select name="type" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }}>
                                        <option>Select Acquirer Type</option>
                                        <option value="qr">QR Code</option>
                                        <option value="collect">Collect</option>

                                    </select>
                                </td>
                            </tr>
                            {
                                getAcquirer.route == "nexacosmos" ?
                                    <tr>
                                        <td>
                                            <label>AES Request Key:</label>
                                        </td>
                                        <td>
                                            <input name="enc_key" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter Encryption Key" />
                                        </td>
                                    </tr>
                                    : <></>
                            }
                            {
                                getAcquirer.route == "fino" ?
                                    <tr>
                                        <td>
                                            <label>ENC KEY:</label>
                                        </td>
                                        <td>
                                            <input name="enc_key" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter Encryption Key" />
                                        </td>
                                    </tr> : <></>
                            }
                            {
                                getAcquirer.route == "nexacosmos" ?
                                    <tr>
                                        <td>
                                            <label>AES Response Key:</label>
                                        </td>
                                        <td>
                                            <input name="dec_key" onChange={(e) => { setAcquirer({ ...getAcquirer, [e.target.name]: e.target.value }) }} placeholder="Enter SALT" />
                                        </td>
                                    </tr> : <></>
                            }
                            {
                                getAcquirer.route == "fino" || getAcquirer.route == "nexacosmos" ?
                                    <tr>
                                        <td>
                                            <label>&emsp;</label>
                                        </td>
                                        <td>
                                            &emsp;
                                        </td>
                                    </tr> : <></>
                            }
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { create_acquirer() }}>Create Acquirer</button>
                    </div>
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">


                        <table>
                            <tr>
                                <th>Acquirer Name</th>
                                <th>VPA</th>
                                <th>MCC</th>
                                <th>Status</th>
                                <th>Route</th>
                                <th>Type</th>
                            </tr>

                            {
                                getAcquirerlist.map((e, index) => {
                                    return (
                                        <tr>
                                            <td>{e.name}</td>
                                            <td>{e.vpa}</td>
                                            <td>{e.mcc}</td>
                                            <td>
                                                {
                                                    e.status ?
                                                        <select name={e.id} onBlur={(e) => { change_state_acquiere(e.target.name, e.target.value) }} className="max-width_60">
                                                            <option value={true}>Active</option><option value={false}>InActive</option>
                                                        </select>
                                                        :
                                                        <select name={e.id} onBlur={(e) => { change_state_acquiere(e.target.name, e.target.value) }} className="max-width_60">
                                                            <option value={false}>InActive</option><option value={true}>Active</option>
                                                        </select>
                                                }
                                            </td>
                                            <td>{e.type}</td>
                                            <td>{e.route}
                                                {e.route == "fino" ? <> <br />{e.payid}</> : <></>}
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </table>
                        <div id="loadingbarhere" class="loader" style={{ width: "100%", display: 'none' }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Acquirer